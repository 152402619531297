import { func } from "prop-types"

const qs = new URLSearchParams(document.location.search || '')
export const DEBUG_ANALYTICS = qs.has('DEBUG_ANALYTICS')
export const ENV = qs.get('ENV')
export const NETWORK = qs.get('NETWORK')
export const RENDERER_TYPE = qs.get('ws') ? 'native' : 'web'
export const CATALYST = addHttpsIfNoProtocolIsSet(qs.get('CATALYST'))
export const PLATFORM = (navigator as any)?.userAgentData?.platform || navigator?.platform || 'unknown'
export const HOSTNAME = document.location.hostname

function insertParam(key, value) {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  // kvp looks like ['key1=value1', 'key2=value2', ...]
  var kvp = document.location.search.substr(1).split('&');
  let i=0;

  for(; i<kvp.length; i++){
      if (kvp[i].startsWith(key + '=')) {
          let pair = kvp[i].split('=');
          pair[1] = value;
          kvp[i] = pair.join('=');
          break;
      }
  }

  if(i >= kvp.length){
      kvp[kvp.length] = [key,value].join('=');
  }

  // can return this or...
  let params = kvp.join('&');

  // reload page with new params
  document.location.search = params;
}

function addHttpsIfNoProtocolIsSet(domain: string | null) {
  if (!domain) {
    addCatalyst()
    return null
  }

  if (!domain.startsWith('http')) {
    return `https://${domain}`
  }

  return domain
}

export function addCatalyst() {
  insertParam('CATALYST', 'unilever-catalyst.untropy.cloud')
}


export function withoutCatalyst(url: string = window.location.href) {
  const newURL = new URL(url)
  newURL.searchParams.delete('CATALYST')
  
  return newURL.toString()
}

export function  withOrigin(url: string | URL, base: string | URL) {
  const finalURL = new URL(url, base)
  const baseURL = new URL(base)
  if (finalURL.toString().startsWith(baseURL.toString())) {
    return finalURL.toString()
  }

  return baseURL.toString()
}

export function ensureOrigin(url: string | URL) {
  console.log('certainy come her')
  switch (HOSTNAME) {
    case 'play.decentraland.org':
      return  withOrigin(url, 'https://cdn.decentraland.org')

    default:
      return url.toString()
  }
}