import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Button } from 'decentraland-ui/dist/components/Button/Button'
import { Modal } from 'decentraland-ui/dist/components/Modal/Modal'
import logoWhite from '../../images/favicon.png'

import { withoutCatalyst } from '../../integration/url'
import { setCatalystAsTrusted } from '../../state/actions'
import { CatalystState, StoreType } from '../../state/redux'
import { track } from '../../utils/tracking'
// import { CatalystWarning } from '../common/Icon/CatalystWarning'
import { Container } from '../common/Layout/Container'
import Main from '../common/Layout/Main'
import { Navbar } from '../common/Layout/Navbar'
import {
  LoginWithPaper,
  PaperSDKProvider
} from "@paperxyz/react-client-sdk";
// import "@paperxyz/react-client-sdk/dist/index.css";
import {
  AuthProvider,
  GetUserStatusType,
  InitializedUser,
  PaperEmbeddedWalletSdk,
  UserStatus,
} from "@paperxyz/embedded-wallet-service-sdk";
import { LogoContainer } from '../auth/LogoContainer'
import './CatalystWarningContainer.css'

export type CatalystWarningProps = CatalystState & {
  onConfirm: () => void
  onCancel: () => void
}

function mapStateToProps(state: StoreType) {
  return {
    ...state.catalyst
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  onConfirm: () => {
    track('trust_catalyst')
    dispatch(setCatalystAsTrusted())
  },
  onCancel: () => {
    track('clear_catalyst')
    window.location.href = withoutCatalyst()
  }
})

export const CatalystWarningContainer = React.memo((props: CatalystWarningProps) => {
  const [paper, setPaper] = useState<PaperEmbeddedWalletSdk>();
  const [userDetails, setUserDetails] = useState<GetUserStatusType>();
  const [user, setUser] = useState<InitializedUser>();
  const [emailAddress, setEmailAddress] = useState<string>();
  const [validEmail, setValidEmail] = useState<boolean>(false);

  useEffect(() => {
    const paper = new PaperEmbeddedWalletSdk({
      clientId: "a61d682e-1b89-4292-9122-501136b366c7",
      chain: "Mumbai",
      // Optional: custom CSS styling properties:
      styles: {
        colorBackground: "#202020",
        colorText: "white",
        colorPrimary: "purple",
        borderRadius: 5,
      },
    });
    setPaper(paper);
  }, []);

  const fetchUserStatus = useCallback(async () => {
    if (!paper) {
      return;
    }
    // console.log("paper", paper);

    const paperUserStatus = await paper.getUserStatus();
    // console.log("paperUserStatus", paperUserStatus);
    setUserDetails(paperUserStatus);
    switch (paperUserStatus.status) {
      case UserStatus.LOGGED_IN_WALLET_INITIALIZED: {
        const paperUserWithWallet = await paper.initializeUser();
        setUser(paperUserWithWallet);
      }
    }
  }, [paper]);

  useEffect(() => {
    if (paper && fetchUserStatus) {
      fetchUserStatus();
    }
  }, [paper, fetchUserStatus]);

  const loginWithEmail = async () => {
    if(emailAddress.endsWith("unilever.com") || emailAddress.endsWith("filmrare.com") || emailAddress.endsWith("langoor.com")) {
      const result = await paper!.auth.loginWithOtp({
        email: emailAddress,
      });
      console.log(`loginWithEmail result: ${result}`);
      props.onConfirm()
     }
     else {
      setValidEmail(true)
     }
    
  };


  const loginWithGoogle = async () => {
    if (!paper) {
      return;
    }
    await paper.auth.initializeSocialOAuth({
      provider: AuthProvider.GOOGLE,
      redirectUri: "http://localhost:3001",
    });
  };

  const googleCallback = async () => {
    if (!paper) {
      return;
    }
    const resp = await paper.auth.loginWithSocialOAuth({
      provider: AuthProvider.GOOGLE,
      redirectUri: "http://localhost:3001",
    });
    console.log("googleCallback response", resp);
  };

  const activateWallet = async () => {
    const response = await paper!.initializeUser();
    console.log("response from activateWallet", response);
    props.onConfirm()
  };

  const logout = async () => {
    const response = await paper!.auth.logout();
    console.log("logout response", response);
  };

  // const confirmEmail = async (code: string) => {
  //   const options = {
  //     method: "POST",
  //     headers: {
  //       Accept: "*/*",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer 13d2cf88-61c6-4832-a83a-2e207e021389`,
  //     },
  //     body: JSON.stringify({
  //       code: code,
  //       clientId: 'd436df83-5669-45f9-a26d-915809a47ae4',
  //     }),
  //   };
  //   const response = await fetch(
  //     "https://cors-anywhere.herokuapp.com/https://paper.xyz/api/v1/oauth/token",
  //     options
  //   );
  //   if (response.status === 200) {
  //     const { userToken } = await response.json();
  //     const resp = await fetch('https://cors-anywhere.herokuapp.com/https://paper.xyz/api/v1/oauth/user-details', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: 'Bearer 13d2cf88-61c6-4832-a83a-2e207e021389',
  //       },
  //       body: JSON.stringify({
  //         userToken,
  //         clientId: 'd436df83-5669-45f9-a26d-915809a47ae4',
  //       }),
  //     })
  //     if (resp.status === 200) {
  //       const userDetails = await resp.json();
  //       console.log(`user details`, userDetails);
  //     }
  //   }
  // };

  return <Main withDarkLayer>
    {/* <Navbar /> */}

    <Container>
      <div className="catalyst-warning-container">
        <div className="catalyst-warning-content">
          {/* <div><CatalystWarning /></div> */}
          {/* <div className="LogoContainer" > */}
            
            
          {/* </div> */}
          <h2>You are about to use Unilever Metaverse</h2>
          <p>Using this custom Metaverse is retricted to Unilever employees.<br />Are you sure you want to proceed?</p>
          <br>
          </br>
          <img alt="untropy" src={logoWhite} className="center" height="40" width="40" />
          <PaperSDKProvider clientId='a61d682e-1b89-4292-9122-501136b366c7' chainName='Polygon'>
          {/* <div>
          <LoginWithPaper onSuccess={confirmEmail}
          />
          </div> */}
          {!userDetails ? (
        <>Loading...</>
      ) : userDetails.status === UserStatus.LOGGED_OUT ? (
        <>
          <input
            type="email"
            placeholder="Email address"
            onChange={(e) => {
              setEmailAddress(e.target.value);
            }}
          />
          <Button primary onClick={loginWithEmail}>Log in with Email</Button>
          <Modal open={validEmail}>
            <Modal.Header>Is this an Unilever email?</Modal.Header>
            <Modal.Content>
              Only Unilever email address is valid.
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={() => setValidEmail(false)}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        </>
      ) : userDetails.status === UserStatus.LOGGED_IN_WALLET_UNINITIALIZED ||
        userDetails.status === UserStatus.LOGGED_IN_NEW_DEVICE ? (
        <>
          Successfully authenticated. Wallet not found on current device.
          <br />
          <br />
          Authenticated email: {userDetails.data.authDetails.email}
          <br />
          <br />
          <Button primary onClick={activateWallet}>
            Activate wallet on this device
          </Button>
        </>
      ) :  (
        <>
          Successfully authenticated and wallet ready to use on this device.
          <br />
          <br />
          Authenticated email: {userDetails.data.authDetails.email}
          <br />
          <div>
            <Button primary onClick={props.onConfirm}>Proceed</Button>
          </div>
          <Button secondary
            onClick={logout}
            // className="m-2 rounded-xl bg-orange-600 px-4 py-2 hover:bg-orange-700 active:bg-orange-800"
          >
            Logout
          </Button>
        </>
      )}
        </PaperSDKProvider>
          {/* <div>
            <Button primary onClick={props.onCancel}>take me out</Button>
          </div> */}
        </div>
      </div>
    </Container>
  </Main>
})

export default connect(mapStateToProps, mapDispatchToProps)(CatalystWarningContainer)
